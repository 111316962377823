.document-card_data-step_line {
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.document-card_data-step_content-text {
    font-weight: 500;
    color: $neutral-100;
    font-size: 28px;
    line-height: 36px;
}


.document-card_data-step {
    border-radius: 12px;
    border: 1px solid $primary-main;
    padding: 28px 20px 28px 40px;

    &:not(:last-child) {
        margin-bottom: 32px;
    }

    .document-card_data-step_line {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .document-card_data-step_content-text {
        font-weight: 500;
        color: $neutral-100;
        font-size: 28px;
        line-height: 36px;
    }
}

.accordion-button-line-content {
    display: flex;
    align-items: center;

    .accordion-button-line_very-button_block {
        cursor: pointer;
        display: flex;
        color: $primary-main;
        margin-left: auto;
        align-items: center;

        .accordion-toggle-text {
            padding-right: 10px;
        }

        .accordion-chevron {
            width: 16px;
            height: 16px;
        }
    }
}