@import "./variables";
@import "./libs/normalize";

@import "./components/all";

body {
    background-color: $neutral-10;
}

.wrapper {
    max-width: 1320px;
    width: 95%;
    margin: 0 auto;
}

.content {
    position: relative;

    display: flex;
    flex-direction: column;

    margin-top: 22px;
    margin-bottom: 22px;
}

.link {
    color: $primary-main;

    &:hover {
        color: $primary-hover;
        text-decoration: underline;
    }

    &:active {
        color: $primary-pressed;
        text-decoration: underline;
    }
}

.filter-panel {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.text-16-bold{
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}



