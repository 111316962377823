.popover {
    position: relative;

    &_close {
        display: none;
    }

    .button-icon {
        width: 16px;
        height: 16px;
    }
}

.dropdown {
    border: 1px solid #6e59d6;

    border-radius: 12px;
    background: $neutral-10;

    text-overflow: ellipsis;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    overflow: hidden;

    &__item {
        width: 113px;
        padding: 6px 16px;
        justify-content: flex-start;
        border: 0;

        background: $neutral-10;

        & > .btn__text {
            color: $primary-main;
        }

        &:hover {
            font-weight: 500;
        }

        &:active {
            & > .btn__text {
                color: $primary-pressed;
            }
        }

        &:focus {
            border-radius: 0;

            & > .btn__text {
                color: $primary-pressed;
            }
        }

        &:disabled {
            cursor: not-allowed;
            background: $neutral-20;

            border-radius: 0;

            & > .btn__text {
                color: $neutral-50;
            }

            &:hover {
                font-weight: 400;
            }
        }
    }
}

