.info-table {
    display: block;
    font-weight: 500;

    .info-table_title {
        color: $neutral-100;
        font-size: 28px;
        line-height: 36px;
    }

    .info-table_subtitle {
        color: $neutral-60;
        font-size: 24px;
        line-height: 32px;
    }

    .info-table_data-separator {
        margin-bottom: 16px;
    }

    .info-table-item-row {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        .info-table-item {
            font-size: 16px;
            line-height: 18px;

            &:not(:first-child) {
                margin-left: 24px;
            }

            .info-table-item-title {
                color: $neutral-60;
            }

            .info-table-item-value {
                margin-top: 4px;
                color: $neutral-100;
            }
        }
    }

}
