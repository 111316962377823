//все цвета
$background-main: #f8f8fa;
$background-input: #f5f4f9;

$black: #232323;

$neutral-100: #0a0a0a;
$neutral-90: #424242;
$neutral-80: #616161;
$neutral-70: #757575;
$neutral-60: #9c9c9c;
$neutral-50: #c2c2c2;
$neutral-40: #e0e0e0;
$neutral-30: #ededed;
$neutral-20: #f9f9f9;
$neutral-10: #ffffff;

$primary-main: #5e47d2;
$primary-bg-color: #f7f5ff;
$primary-pressed: #220f80;
$primary-hover: #7f66ff;
$primary-focus: #e2dcff;
$primary-border: #bcb0f5;
$primary-text: #694df9;

$success-main: #21725e;
$success-bg-color: #e7faf5;
$success-pressed: #164c3f;
$success-hover: #1b5f4e;
$success-focus: #c8ebe2;
$success-border: #b5d0c9;

$warning-main: #e0ce2c;
$warning-bg-color: #fefadc;
$warning-pressed: #95891d;
$warning-hover: #bbac25;
$warning-focus: #fdf8cf;
$warning-border: #f5efb9;

$danger-main: #a82525;
$danger-bg-color: #ffe8e8;
$danger-pressed: #541212;
$danger-hover: #8c1f1f;
$danger-focus: #ffdfdf;
$danger-border: #e2b6b6;

$info-main: #0023dd;
$info-bg-color: #ebeeff;
$info-pressed: #001793;
$info-hover: #001db8;
$info-focus: #e2e7ff;
$info-border: #aab6f4;

$font-family: Poppins;
// $container-width: 90%;

// $duration: 0.15s;

