.documents-table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 12px;
    border: 1px solid $neutral-40;

    background-color: $neutral-10;
    overflow: hidden;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &__link {
        cursor: pointer;
        & > div {
            border-bottom: 1px solid $neutral-40;
        }

        &:last-child > div {
            border-bottom: 0;
        }
    }

    &-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1.5fr 1fr 1fr 1fr;
        min-height: 56px;

        div {
            display: flex;
            align-items: center;
            padding: 18px 16px;
        }

        &__link {
            color: $primary-main;
        }

        &__link-wrapper {
            color: $primary-main;
            overflow: auto;
            text-overflow: ellipsis;
        }

        &__status {
            .status-bg {
                display: flex;
                width: 109px;
                padding: 2px 8px;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
            }

            .created {
                background: $neutral-40;
                color: $neutral-90;
            }

            .booked {
                background: $warning-bg-color;
                color: $warning-hover;
            }

            .issued {
                background: $info-bg-color;
                color: $info-hover;
            }

            .completed {
                background: $success-bg-color;
                color: $success-hover;
            }

            .rejected {
                background: $danger-bg-color;
                color: $danger-hover;
            }
        }

        &:hover {
            background-color: $neutral-20;
        }
    }

    &-head {
        // height: 44px;
        min-height: 44px;
        background-color: $neutral-10;

        & > div > p {
            color: $neutral-70;
            font-size: 14px;
        }

        &__item {
            display: flex;
            justify-content: flex-start;
            gap: 10px;
            align-items: center;
            cursor: pointer;
        }

        &:hover {
            background-color: $neutral-10;
        }

        .sort-icon {
            height: 16px;
            padding: 0;
        }

        & + .documents-table__link {
            border-top: 1px solid $neutral-40;
        }
    }

    &_search {
        width: 355px;
    }
}

