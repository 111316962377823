.table {
    &_empty {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 150px;

        .title {
            color: $neutral-100;
            // font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }

        .text {
            color: $neutral-80;
            // font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }
}

