.custom-select {
    &__wrapper {
        width: 179px;
        min-height: 40px;
    }

    &__control {
        padding: 6px 12px;
        // min-height: 40px;

        .css-1fdsijx-ValueContainer {
            padding: 0px;
        }
    }
}

//     /* Стили для выпадающего меню */
//     .my-select__menu {
//         background-color: #ffffff;
//         border: 1px solid #00796b;
//         z-index: 999;
//     }

//     /* Стили для каждой опции */
//     .my-select__option {
//         padding: 10px;
//         color: #333;
//         cursor: pointer;
//         transition: background-color 0.3s ease;
//     }

//     /* Стили для выделенной опции */
//     .my-select__option--is-selected {
//         background-color: #00796b;
//         color: #fff;
//     }

//     /* Стили для опции при наведении */
//     .my-select__option--is-focused {
//         background-color: #e0f7fa;
//     }

//     /* Стили для выбранного значения */
//     .my-select__single-value {
//         color: #333;
//     }

//     /* Стили для стрелочки */
//     .my-select__dropdown-indicator {
//         color: #00796b;
//         transition: color 0.3s ease;
//     }

//     /* Стили для стрелочки при наведении */
//     .my-select__dropdown-indicator:hover {
//         color: #004d40;
//     }

//     /* Скрытие разделителя между стрелочкой и текстом */
//     .my-select__indicator-separator {
//         display: none;
//     }
// }

