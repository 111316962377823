.tabs {
    &__list {
        margin-bottom: 22px;

        .active::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            height: 3px;
            width: 100%;
            background: $primary-main;
        }
    }

    &__item {
        position: relative;
        display: inline-block;
        padding: 0 8px 5px 8px;
        text-decoration: none;
        cursor: pointer;
        width: max-content;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            display: block;
            background: none repeat scroll 0 0 transparent;
            height: 3px;
            width: 0;
            background: $primary-main;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
        }

        &:hover::after {
            width: 100%;
            left: 0;
        }

        &_active::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            height: 3px;
            width: 100%;
            background: $primary-main;
        }

        .tablinks {
            color: $neutral-100;
            // font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
    }

    &_empty {
        height: 36px;
        padding: 0 8px 5px 8px;
    }
}

