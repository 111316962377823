@import "../variables";

.header {
    background-color: $neutral-10;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &-wrapper {
        height: 72px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .icon__circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
        .icon__cart {
            background: $primary-bg-color;
        }

        .icon__profile-name {
            background: $primary-main;

            .profile-name {
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                color: $neutral-10;
            }
        }
    }
}

