.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;

    &.active {
        opacity: 1;
        pointer-events: all;
    }

    &__title {
        color: $neutral-90;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        padding-bottom: 8px;
    }

    &__text {
        color: $neutral-60;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-bottom: 24px;
    }

    &__content {
        padding: 36px 40px;
        border-radius: 8px;
        background-color: $neutral-10;
        border: 1px solid $neutral-30;
        width: 465px;
        transform: scale(0.5);
        transition: 0.4s all;
        box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.12);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.active {
            transform: scale(1);
        }
    }
}

