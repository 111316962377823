.menu__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;

    &-item {
        padding: 16px;
        display: flex;
        align-items: center;
    }

    &-link {
        position: relative;
        display: inline-block;
        padding: 0 8px 5px 8px;
        text-decoration: none;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            display: block;
            background: none repeat scroll 0 0 transparent;
            height: 3px;
            width: 0;
            background: $primary-main;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
        }

        &:hover::after {
            width: 100%;
            left: 0;
        }

        &_active::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            height: 3px;
            width: 100%;
            background: $primary-main;
        }
    }
}

