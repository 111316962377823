.user-card {
    &__wrapper {
        position: relative;
        // height: 80vh;
        background-color: $neutral-10;
        border-radius: 12px;

        padding-bottom: 30px;
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding-top: 40px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 34px;
    }

    &__title {
        color: $neutral-100;

        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    &__status {
        padding-top: 20px;
    }
}

