@import "../variables";

.button {
    cursor: pointer;
    padding: 8px 16px;
    border: 0;
    background-color: transparent;
    span {
        color: $primary-main;
        font-size: 14px;
        text-align: center;
    }

    svg {
        stroke: $primary-main;
    }

    &:hover {
        span {
            color: $primary-hover;
        }
        svg {
            stroke: $primary-hover;
        }
    }

    &:active {
        span {
            color: $primary-pressed;
        }
        svg {
            stroke: $primary-pressed;
        }
    }

    &:focus {
        border-radius: 12px;
        border: 2px solid $primary-focus;

        span {
            color: $primary-main;
        }
        svg {
            stroke: $primary-main;
        }
    }

    &_disabled {
        cursor: not-allowed;
    }
}

