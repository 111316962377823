.go_back_button {
    display: flex;
    align-items: center;
    width: fit-content;

    .go-back_icon {
        height: 28px;
        width: 32px;
        cursor: pointer;
    }

    .go-back_text {
        margin-left: 12px;

        font-weight: 500;
        color: $neutral-100;
        font-size: 36px;
        line-height: 44px;
    }
}