.search {
    position: relative;

    &__input {
        width: 188px;
        height: 40px;
        border-radius: 12px;
        border: 1px solid $neutral-40;
        background: $neutral-10;
        padding: 6px 12px;
        padding-left: 32px;

        font-size: 14px;
        color: $neutral-50;
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        &::placeholder {
            color: $neutral-50;
        }

        &:hover {
            border: 1px solid $primary-main;
            background: $neutral-10;
        }

        &:focus {
            border: 3px solid $primary-focus;
            background: $neutral-10;
            padding: 6px 10px;
            padding-left: 32px;
        }
    }

    &__icon {
        width: 16px;
        height: 16px;

        // opacity: 0.3;
        position: absolute;
        left: 12px;
        top: 11px;

        path {
            stroke: $neutral-60;
        }
    }

    &-and-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
}

