.button-scroll {
    cursor: pointer;

    border: 0;
    text-align: center;
    box-sizing: border-box;
    transition: transform 3s;

    background-color: transparent;

    .hidden {
        display: none;
    }
}

.react-horizontal-scrolling-menu {
    &--inner-wrapper {
        align-items: center;
    }

    &--scroll-container {
        gap: 16px;
    }

    &--arrow-right,
    &--arrow-left {
        width: 96px;

        position: absolute;
        top: -8px;
        z-index: 1;
    }

    &--arrow-right {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 53%);
        right: 0;
        justify-content: flex-end;
    }

    &--arrow-left {
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 53%);
        left: 0;
        justify-content: flex-start;
    }
}

